(function () {
  'use strict';

  angular
    .module('learning.editModule')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard.learning.editModule', {
        url: '/edit-module/:id',
        templateUrl: 'dashboard/learning/edit-module/edit-module.tpl.html',
        controller: 'EditModuleCtrl',
        controllerAs: 'editModule',
        roles: ['superadmin', 'admin', 'Teacher']
      });
  }
}());
